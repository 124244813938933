import styles from './index.module.css'
const Home = () => {
    
    return (
        <div className={styles.box}>
            <ul>
                <li><a href={`./playlist?id=7486016882&limit=20`} target="_blank" rel="noreferrer">Playlist页面</a></li>
                <li><a href={`./artist?id=44204&limit=20`} target="_blank" rel="noreferrer">Artist页面</a></li>
            </ul>

            <br></br>
            <h3>厂牌</h3>
            <ul>
                <li><a href={`./playlist?id=6780769280&limit=20`} target="_blank" rel="noreferrer">星尘厂牌 | Stardust Records</a></li>
                <li><a href={`./playlist?id=7380724212&limit=20`} target="_blank" rel="noreferrer">梦中迷失 | Lost In Dreams</a></li>
                <li><a href={`./playlist?id=5305645501&limit=20`} target="_blank" rel="noreferrer">天籁之音 | Paradise Music</a></li>
                <li><a href={`./playlist?id=594243151&limit=20`} target="_blank" rel="noreferrer">Lowly Palace</a></li>
            </ul>

            <br></br>
            <h3>制作人</h3>
            <ul>
                <li><a href={`./artist?id=40245&limit=20`} target="_blank" rel="noreferrer">MitiS</a></li>
                <li><a href={`./artist?id=44204&limit=20`} target="_blank" rel="noreferrer">Seven Lions</a></li>
                <li><a href={`./artist?id=1132078&limit=20`} target="_blank" rel="noreferrer">ARMNHMR</a></li>
            </ul>

        </div>
    )
}
export default Home


