import { useState, useEffect } from 'react'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'

import './index.css'
import'..//public.css'

const Playlist = () => {
    const [state, setState] = useState({
        album: {},
        playlist: {},
        firstPicUrl: null,
    })

    const [params] = useSearchParams()
    const id = params.get('id') || 6780769280
    const limit = params.get('limit') || 20

    useEffect(() => {
        // https://music.163.com/#/playlist?id=6780769280
        axios.all([
            axios.get(`https://neteasecloud.bandao.ltd/playlist/track/all?id=${id}&limit=${limit}`),
            axios.get(`https://neteasecloud.bandao.ltd/playlist/detail?id=${id}`)
        ])
        .then(
            response => {
                // console.log(response);
                setState({
                    album: response[0].data.songs,
                    playlist: response[1].data.playlist,
                    firstPicUrl: response[0].data.songs[0].al.picUrl,
                })
                document.title = `Playlist - ${response[1].data.playlist.name}`
            },
            error => {
                console.log(error.message);
            }
        );
    }, [id, limit])

    // console.log(state);
    const { album, playlist, firstPicUrl } = state;
    return (
        <div className="bigBox">
            <img src={firstPicUrl} className="bgImg" alt="" />
            <div className="artist">
                <div>
                    <img src={`${playlist.coverImgUrl}?param=177y177`} alt="" className="artistImg" />
                </div>
                <div className="playlistInfo">
                    <a href={`https://music.163.com/#/playlist?id=${playlist.id}`} target="_blank" rel="noreferrer">
                        <p className="artistName">
                            {playlist.name}
                        </p>
                    </a>
                    <p className="playlistDesc">
                        歌单详情：{playlist.description}
                    </p>
                </div>
            </div>
            <div className="List">
                {
                    album.length ? album.map(albumObj => {
                        return (
                            <a key={albumObj.id} href={`https://music.163.com/song?id=${albumObj.id}`} className="albumBox" target="_blank" rel="noreferrer">
                                <div className='albumImgBox'>
                                    <img alt="head_portrait" src={`${albumObj.al.picUrl}?param=177y177`} style={{ width: '100px' }} className='albumImg' />
                                </div>
                                <p className="albumName">{albumObj.name}</p>
                            </a>
                        )
                    }) : ''
                }
            </div>
        </div>
    )

}
export default Playlist