// 1.引入一个方法和一个组件
// createBrowserRouter - 创建路由实例 在方法中定义路由path和组件的对应关系
import { createBrowserRouter } from 'react-router-dom'

// 配置路由对应关系
// 2.调用create方法生成实例
import Artist from '../pages/artist'
import Playlist from '../pages/playlist'
import Home from '../pages/home'
const router = createBrowserRouter([
    {
        path: '/',
        element: <Home/>,
    },
    {
        path: '/playlist',
        element: <Playlist/>,
    },
    {
        path: '/artist',
        element: <Artist/>,
    },
    {
        path: '*',
        element: <div>66666</div>
      },
])
export default router