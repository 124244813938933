import { useState, useEffect } from 'react'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'
import './index.css'
// import '../public.css'

const Artist = () => {
    const [state, setState] = useState({
        album: {},
        artist: {},
        firstPicUrl: null,
    })

    const [params] = useSearchParams()
    const id = params.get('id') || 1132078
    const limit = params.get('limit') || 20

    useEffect(() => {

        // http://neteasecloud.bandao.ltd/artist/album?id=1132078&limit=30
        axios.get(`https://neteasecloud.bandao.ltd/artist/album?id=${id}&limit=${limit}`).then(
            response => {
                setState({
                    album: response.data.hotAlbums,
                    artist: response.data.artist,
                    firstPicUrl: response.data.hotAlbums[0].picUrl,
                })
                document.title = `Artist - ${response.data.artist.name}`
            },
            error => {
                console.log(error.message);
            }
        );

    }, [id, limit])

    // console.log(state.a);
    const { album, artist, firstPicUrl } = state;

    return (
        <div className="bigBox">
            <img src={firstPicUrl} className="bgImg" alt="" />
            <div className="artist">
                <div>
                    <img src={`${artist.picUrl}?param=177y177`} alt="" className="artistImg" />
                </div>
                <div className="artistInfo">
                    <a href={`https://music.163.com/#/artist?id=${artist.id}`} target="_blank" rel="noreferrer">
                        <p className="artistName">
                            {artist.name}
                        </p>
                    </a>
                    <p className="artistAlbumNumber">
                        专辑数量：{artist.albumSize}
                    </p>
                </div>
            </div>
            <div className="List">
                {
                    album.length ? album.map(albumObj => {
                        return (
                            <a key={albumObj.id} href={`https://music.163.com/#/album?id=${albumObj.id}`} className="albumBox" target="_blank" rel="noreferrer">
                                <div className='albumImgBox'>
                                    <img alt="head_portrait" src={`${albumObj.picUrl}?param=177y177`} style={{ width: '100px' }} className='albumImg' />
                                </div>
                                <p className="albumName">{albumObj.name}</p>
                            </a>
                        )
                    }) : ''
                }
            </div>
        </div>
    )

}
export default Artist