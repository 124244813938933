//引入react核心库
import React from 'react';
//引入ReactDOM
// import ReactDOM from 'react-dom';
//引入App组件
// import App from './App'

import { createRoot } from 'react-dom/client';

//渲染App到页面(React18)
// const container = document.getElementById('root')
// const root = createRoot(container)
// root.render(<App />)



// 1.引入一个方法和一个组件
// RouterProvider - 作为一个组件渲染 并且传入createBrowserRouter执行之后生成的router实例
import { RouterProvider } from 'react-router-dom'
import router from './router'
createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
)


//渲染App到页面(React17)
// ReactDOM.render(<App />, document.getElementById('root'))
